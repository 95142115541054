ul {
    list-style: none;
    padding-left: 5px;
  }
  
  .box {
    width: 200px;
    height: 50px;
    margin-bottom: 5px;
  }
  
  .red {
    background: red;
  }
  
  .green {
    background: green;
  }

  /* DatePickerStyles.css */

/* Override the default styles of react-datepicker */
.react-datepicker {
  border: none;
  border-radius: 4px;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
  background-color: white;
  border-bottom: none;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  color: black;
}

.react-datepicker__day {
  color: black;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: black !important;
  color: white !important;
}

.react-datepicker__day--today {
  font-weight: bold;
}


.react-datepicker__month {
  margin: 10px;
}
.react-datepicker-popper {
  z-index: 100000000000;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

