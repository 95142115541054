.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  color: #333;
  padding: 24px;
  border-radius: 12px;
  width: 255px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.modal-content h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 25px;
  color: #222;
  text-align: left;
}

.modal-content label {
  display: block;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #555;
  text-align: left;
}



.week-days {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
}

.week-days button {
  padding: 8px 12px;
  border: 1.5px solid #ccc;
  background: #f9f9f9;
  color: #333;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;

}

.week-days button.selected {
  background: #007bff;
  color: #fff;
  border: 1.5px solid #007bff;
}


.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 40px;
}


