

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid rgb(255, 0, 0);
    padding: 8px;
}

pre {
    white-space: pre-wrap;
}
